import React, { Component } from "react";

export default class Blog3 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">How To Distribute Tokens For Your Binance Giveaway </h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Do you intend to run a token giveaway using BNB or BEP20 tokens? If you do, we have a reliable way of sending prizes to people.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Any giveaway, regardless of the scale of the company that’s doing it, needs a reliable way of distributing cryptocurrency prizes. Otherwise, how do you possibly expect to be able to fulfil your end of the giveaway?</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Well, we think we’ve got the answers - it’s all about BNB and Binance tokens. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Tokens can be a great resource to look into, so let’s check out what we mean. Your giveaway needs tokens, and here’s why. </p>
                                                <img src="assets/img/binance_1.png" style={{ height: '500px', marginLeft: '275px', marginTop: '30px', marginBottom: '30px' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Easy to Work With</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Binance Tokens are so easy to work with which is great. With Binance Sender the process for distributing tokens is now even easier.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; BNB and BEP20 tokens have many appealing characteristics, it’s part of their unique charm. Tokens work by simply sending them all at once with Binance Senders bulk sending tool.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Being this easy to work with means that businesses will be relying on them to get stuff done.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; There’s an ever-present quest to streamline business practices - things need to be easier if companies are going to maximise efficiency. That’s what tokens aim to do. They make the process a lot easier. You can send so many tokens to a bunch of different people with no issues.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2><a href="https://binancesender.com/">Batch-Sending</a></h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Being able to send a large batch of tokens all at once is definitely a bonus for any company.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Naturally, if you’re running a competition with 10 to 12 giveaways, then you will want to make sure that you can send 10 to 12 tokens all at once. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; This can be a problem trying to do it by hand one at a time because it will take you longer.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Any business will want to maximise their efficiency and make sure that they get the most done in the least of their time, which is why it’s a good idea to have all of these resources.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Reliable Process</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Your business needs tokens for its giveaway because they are easy and reliable to work with. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Imagine for a second that you need to try and send a prize to people with your giveaway.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Now obviously, you want to send something that is a fixed value, has no cash equivalent, and cannot be sold or traded by other means.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; That’s why you need to make sure that you have tokens because they fit the bill nicely.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp;  Rather than a voucher, which may have loopholes in it, a token can be used to redeem one transaction at a time and is a good way of keeping track of your giveaway prizes.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Final Thoughts</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; So, in conclusion, there are a lot of different reasons why your business needs giveaway tokens.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; It’s a very powerful resource that can provide you with a lot of different options, so it’s definitely worth checking out what’s on offer.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The good thing about prize giveaways and tokens is that they are a fast and easy way to get done what you need to get done and to give you all of the options you could possibly one.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; There are many different ways you can do things in this particular situation, so it’s well worth checking out what’s on offer.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; To Batch send tokens for your project visit Binance Sender - <a href="https://binancesender.com/">https://binancesender.com/</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}