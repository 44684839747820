import React, { Component } from "react";

export default class Blog10 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">Why BNB Should be Part of Your Investment Portfolio</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; BNB is the native token of Binance and has one of the most remarkable growth over the last few months. At beginning of February 2021, BNB was hit a record $50 for the first time. However, in a week the token had hit another milestone with a more than 150% rise to reach $148. Today, BNB is trading for more than $360 and is projected to continue the growth.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The growth of BNB is attributed to its growing community of users, mostly from the Binance Smart Chain (BSC) platform that supports DApp developers. The Binance community can only be described as robust, with many projects taking advantage of the BSC compatibility with Ethereum’s EVM and programmability. That is, BNB is rising in value every day because of the increased porting between Ethereum and BSC.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The other important factor driving BNB bullishness is the increased crypto-financial projects that are being developed on the BSC platform. Often known as decentralized finance (DeFi), these projects are sprouting everywhere because of the promise to revolutionize the financial sector. BSC is now home to dozens of new projects. For example, DApps such as PancakeSwap are making massive waves in the financial sector.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The latest application that’s likely to drive the adoption of BNB is the Token Multisender known as <a href="https://binancesender.com/">BinanceSender</a>. With BinanceSender, you can send BNB and BEP20 tokens from a CSV file to a different Binance address in a single transaction. The project takes into consideration the increased need to send airdrop BNB, distributed tokens, BEP20 tokens, etc. These applications are only making BNB and any BEP20 token more popular with users, which is likely to increase project adoption.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}