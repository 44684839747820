import React, { Component } from "react";

export default class Blog12 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">Our Hopes For the Future of Binance Tokens</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; So, what is it that we want to accomplish with our token sending activities here? What is it that we want to do with all of this potential? Well, we do have a couple of different ideas with regards to the future.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; We are, of course, constantly thinking about what we want from the future, and how it will affect everything that we do. We want to improve our Binance token sending process, but that’s not all we want to do. We do actually have a lot of other ideas and beliefs about the future, so we are going to talk about some of them here and now.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Improve the Service </h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Naturally, one of the things that we do want to make sure we do is improve the service that you have. We constantly intend to make sure that you can distribute tokens as easily as possible, because we understand how important it can be.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; That is why we will constantly improve the token sending policies and procedures that we have, so that you can get the best results. We know how important it can be to have a smooth, constantly evolving, and easy to work with piece of technology, which is exactly what we set out to provide you with. Naturally, we want you to get the best results, so we will work hard to make sure that you have them.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>The Optimum Strategies</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Something else that we intend to do is to provide you with the optimum strategies for sending large quantities of tokens all at once. We understand how important it can be for you to get the best possible results in this endeavour, which is exactly what we intend to provide you with.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; It is through careful examination of the options that we have been able to give you the best choices. We know how important it can be for you to get what you need, and so we actively strive to make sure you can have it. It is our fondest intention to make sure your experiences are positive ones.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Offering More Services</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Something else that we intend to do is to provide you with even more services. We understand just how important it can be for you to get the best possible resources, which is why we want to make sure that the services you have continue to grow. We want to progress with the future, so we will make sure that you have access to what you want, whenever you want it when it comes to Binance tokens.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Final Thoughts</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; So, obviously, we do strive to give you the best resources, because we understand just how important it can be. We want you to feel like you’ve got access to everything you want, which is why we work hard to give you the best choices. We have put a lot of effort into generating the optimum results for you, and we will endeavour to keep doing so as much as possible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}