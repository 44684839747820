import React, { Component } from "react";

export default class Blog7 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">BNB Airdrops and How You Can Send Them in Bulk </h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Many crypto projects distribute free coins to their communities to increase their visibility, circulation and stimulate enthusiasm about their coins or project. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Commonly referred to as airdrops, many crypto enthusiasts get to participate in such projects to earn airdrops.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Both Binance Chain and Binance Smart Chain (BSC) have had projects run on their respective platforms powered by BNB, and these projects often distribute BNB to project participants.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Sometimes you may need to send your BNB tokens in bulk.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Read on to understand how BNB airdrops works and how you can send them once you earn them.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Most airdrops come in the form of bounty rewards. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Airdrops that come in the form of bounties are rewards for completing some simple tasks such as sharing social media posts about the new project, joining their telegram group, etc.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The project gets free publicity on the social media platforms while and they reciprocate this free publicity by rewarding those who carry out these simple tasks.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Some projects may reward you with airdrops if you hold a specific coin without expecting any of you to reciprocate with anything.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Airdrops can be rewarding, especially if the project becomes successful.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Taking advantage of such programs can give you a head-start in owning a new crypto token.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>How can you bulk send your BNB airdrops?</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Sending and receiving BNB and BEP20 tokens is the most popular activity between Binance-based projects.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; If you receive BNB or BEP20 tokens in the form of airdrops, then you can use one of the most trusted multi-sender, <a href="https://binancesender.com/">BinanceSender</a>, which works for both BNB and BEP20 tokens.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; BinanceSender is a project that lets users send BNB and BEP20 tokens from a CSV file to different Binance addresses in a single transaction.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}