import React, { Component } from "react";

export default class Blog1 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">Binance Sender — The Easiest way to send BNB & BEP20 tokens to multiple addresses </h2>
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; sending and receiving BNB and BEP20 based tokens is a huge requirement for project owners.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; For Project owners, bounty managers and organisers of airdrops or events who usually send tokens to a number of users, sending to the individual address separately is a time consuming and hectic task. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The team and Binance Sender realise that a better solution is needed so sending Binance tokens to multiple addresses can be done in a single click, which can also save a lot of time & energy.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="left-text">
                                                        <img src="assets/img/1.png" style={{ height: '500px', marginLeft: '350px', marginTop: '30px', marginBottom: '30px' }}></img>
                                                        <h6 style={{ textAlign: 'center' }}>Visit <a href="https://binancesender.com/">https://binancesender.com/ </a></h6>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="right-text">
                                                <h4>What is a Token Bulk Sender</h4>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; This Binance Sender DAPP has been created to send Binance based tokens to multiple wallet addresses in one transaction from a CSV or txt file saving valuable time when sending large numbers of tokens such as airdrops, ICO distributions, payroll etc.
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="right-text">
                                                <h4>How to use Binance Sender</h4>
                                                <p className="blog-content">Step 1: Visit <a href="https://binancesender.com/">https://binancesender.com/ </a> </p>
                                                <p className="blog-content">Step 2: Prepare address list (CSV file)</p>
                                                <p className="blog-content">Step 3: Make sure your wallet is on Main net</p>
                                                <p className="blog-content">Step 4: Select the token you wish to send from the drop-down menu</p>
                                                <p className="blog-content">Step 5: Click upload CSV and select your airdrop list (format must be addressed then balance separated by a comma and in the same column)</p>
                                                <p className="blog-content">Step 6: Click Approve (This will prompt transactions in your wallet to approve the amount you want to send.)</p>
                                                <p className="blog-content">Step 7: Check the airdrop summary</p>
                                                <p className="blog-content">Step 8: Click send</p>
                                                <p className="blog-content">Step 9: Receive transaction information</p>
                                                <p className="blog-content">Visit the <a href="https://binancesender.com/">https://binancesender.com/ </a>  site and begin your token distribution today</p>
                                                <p className="blog-content">For Distributing Tron, TRC10 and TRC20 tokens you can visit  <a href="https://tronsender.com/"> https://tronsender.com/ </a> </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}