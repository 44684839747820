import React, { Component } from "react";

export default class Blog4 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">How to send multiple transactions on the Binance network</h2><br />
                                                <div className="line-dec"></div>
                                                <div style={{ textAlign: 'center' }}><img src="assets/img/binance_2.png" className="blog-img15"></img></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; How to send BNB and BEP20 tokens from one address to multiple addresses.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; <a href="https://binancesender.com/">https://binancesender.com/</a>  is a Utility tool for bulk sending BNB and BEP 20 tokens to multiple addresses from a CSV or txt file.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>What is this Tool for?</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; For many project owners the idea of having to send thousands of tokens to potentially hundreds of wallet addresses can be daunting.   To assist with this problem we have created the Binance Sender Dapp, a useful tool for batch sending tokens to Multiple wallet addresses in 3 simple steps.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; To send the tokens users simply to require the wallet addresses they wish to send Binance tokens to and the number of tokens they are looking to send to each address, the Binance Sender platform will provide all the rest of the requirements including an address validator and a duplicate address checker assisting project owners with identifying additional errors where possible.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Introduction</h2><br />
                                                <p className="blog-content">How to use <a href="https://binancesender.com/">https://binancesender.com/ </a> </p>
                                                <p className="blog-content">Step 1: Visit <a href="https://binancesender.com/">https://binancesender.com/ </a> </p>
                                                <p className="blog-content">Step 2: Prepare address list (CSV file)</p>
                                                <p className="blog-content">Step 3: Make sure your wallet is on Binance Main net</p>
                                                <p className="blog-content">Step 4: Select the token you wish to send from the drop-down menu</p>
                                                <p className="blog-content">Step 5: Click upload CSV and select your airdrop list (format must be, wallet address in column 1 and the token value in column 2)</p>
                                                <p className="blog-content">Step 6: Click Approve (This will prompt transactions in your Binance wallet asking to approve the amount you want to send.)</p>
                                                <p className="blog-content">Step 7: Check the Binance Sender summary</p>
                                                <p className="blog-content">Step 8: Once happy everything is correct Click Send</p>
                                                <p className="blog-content">Step 9: Binance Send completed - Receive the transaction information </p>
                                                <p className="blog-content">Visit the <a href="https://binancesender.com/">https://binancesender.com/ </a>  site and begin your token distribution today</p>
                                                <p className="blog-content">For Distributing Tron, TRC10 and TRC20 tokens you can visit  <a href="https://tronsender.com/"> https://tronsender.com/ </a> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}