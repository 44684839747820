import React, { Component } from "react";
import { BrowserRouter, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

export default class Blogs extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blogs");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blogs" style={{ paddingTop: '170px'}}>

                <div className="row">


                    <div className="col-md-12">
                        <div className="calc-wrap">

                            <div className="section-heading">
                                <h1 className="text-center">Blogs</h1>
                                <div className="line-dec"></div>
                                {/* <span>
                                        Praesent posuere fringilla elit, non maximus tellus posuere nec. Nunc eu sapien nec est
                                        elementum sodales.
                                    </span> */}
                            </div>

                            <div className="row" data-aos="fade-up">
                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box"id="blogImg">
                                        <div className="col-md-3 " style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div  className="col-md-9 ">
                                            <h3 className="tm-text-blue">Binance Sender — The Easiest way to send BNB & BEP20 tokens to multiple addresses
                                            </h3>
                                            <p>sending and receiving BNB and BEP20 based tokens is a huge requirement for project owners. </p>
                                            <Nav.Link as={Link} to="/Binance_Sender_The_Easiest_way_to_send_BNB_BEP20_tokens_to_multiple_addresses" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">BinanceSender: Bulk Send BNB and BEP20 Tokens from a CSV File</h3>
                                            <p>
                                                Binance coin was created to operate as a native crypto asset known as BNB. The coin offers an alternative way to pay for fees charged to traders using the Binance Exchange. </p>
                                            <Nav.Link as={Link} to="/BinanceSender_Bulk_Send_BNB_and_BEP20_Tokens_from_a_CSV_File" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">How To Distribute Tokens For Your Binance Giveaway</h3>
                                            <p>Do you intend to run a token giveaway using BNB or BEP20 tokens? If you do, we have a reliable way of sending prizes to people. </p>
                                            <Nav.Link as={Link} to="/How_To_Distribute_Tokens_For_Your_Binance_Giveaway" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">How to send multiple transactions on the Binance network</h3>
                                            <p>How to send BNB and BEP20 tokens from one address to multiple addresses.
                                            </p>
                                            <Nav.Link as={Link} to="/How_to_send_multiple_transactions_on_the_Binance_network" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">How to Bulk Send Tokens</h3>
                                            <p>Being able to bulk send tokens for any situation is important. Obviously you’re going to want to make sure that you generate the best results for yourself in any given situation. It is definitely not the easiest thing to do as such, but it can be vital for getting the optimum results.
                                            </p>
                                            <Nav.Link as={Link} to="/How_to_Bulk_Send_Tokens" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">How to Distribute Binance Tokens to Multiple Addresses </h3>
                                            <p>
                                                Now obvuously, you’ll want to know how to send all of your Binance tokens to the right place for the sake of easy distribution. </p>
                                            <Nav.Link as={Link} to="/How_to_Distribute_Binance_Tokens_to_Multiple_Addresses" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">BNB Airdrops and How You Can Send Them in Bulk </h3>
                                            <p>Many crypto projects distribute free coins to their communities to increase their visibility, circulation and stimulate enthusiasm about their coins or project.
                                            </p>
                                            <Nav.Link as={Link} to="/BNB_Airdrops_and_How_You_Can_Send_Them_in_Bulk" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">How to Access the Best Airdrops Offers in the Market</h3>
                                            <p>One of the most popular ways of getting involved with a new project is getting to participate in the early stages of its development and launch.
                                            </p>
                                            <Nav.Link as={Link} to="/How_to_Access_the_Best_Airdrops_Offers_in_the_Market" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">Why Binance Smart Chain (BSC) is Making Waves in the World of Crypto</h3>
                                            <p>Binance is the largest crypto exchange by trading volume. But Binance is not just an exchange but a massive ecosystem hosting many projects through its Binance Chain and Binance Smart Chain (BSC).
                                            </p>
                                            <Nav.Link as={Link} to="/Why_Binance_Smart_Chain_BSC_is_Making_Waves_in_the_World_of_Crypto" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">Why BNB Should be Part of Your Investment Portfolio</h3>
                                            <p>BNB is the native token of Binance and has one of the most remarkable growth over the last few months. At beginning of February 2021, BNB was hit a record $50 for the first time.
                                            </p>
                                            <Nav.Link as={Link} to="/Why_BNB_Should_be_Part_of_Your_Investment_Portfolio" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">Why decentralized finance (DeFi) is the future of finance</h3>
                                            <p>While Bitcoin has been stealing the limelight for its erratic price movements over the last few months, DeFi projects are mushrooming everywhere with massive promise to revolutionize the financial industry. 
                                            </p>
                                            <Nav.Link as={Link} to="/Why_decentralized_finance_DeFi_is_the_future_of_finance" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">Our Hopes For the Future of Binance Tokens</h3>
                                            <p>So, what is it that we want to accomplish with our token sending activities here? What is it that we want to do with all of this potential?
                                            </p>
                                            <Nav.Link as={Link} to="/Our_Hopes_For_the_Future_of_Binance_Tokens" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">The Top Benefits to Using Binance Sender</h3>
                                            <p>So, when it comes to sending tokens en-masse, you do need to think a bit about how you’re going to do it and what that means for your experiences.</p>
                                            <Nav.Link as={Link} to="/The_Top_Benefits_to_Using_Binance_Sender" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">What to Look For in a Token Multisender</h3>
                                            <p>So, if you’re going to get the best possible options when it comes to sending tokens in bulk, you need to make sure that you’ve picked the right kind of sending power to do it with. 
                                            </p>
                                            <Nav.Link as={Link} to="/What_to_Look_For_in_a_Token_Multisender" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="row blog-box" id="blogImg">
                                        <div className="col-md-3" style={{textAlign:'center'}}>
                                            <img src="assets/img/favicon.png" alt="Image" className="tm-activity-img" />
                                        </div>
                                        <div className="col-md-9">
                                            <h3 className="tm-text-blue">Why Your Binance Tokens Business Needs Binance Sender </h3>
                                            <p>If you’re going to run a BNB token business you need to make sure that you have a reliable method of sending these BInance tokens to people so they can use them on your website. 
                                            </p>
                                            <Nav.Link as={Link} to="/Why_Your_Binance_Tokens_Business_Needs_Binance_Sender" style={{ width: '200px', display: 'inline' }}>Read More</Nav.Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}