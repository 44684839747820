import React, { Component } from "react";

export default class Blog5 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">How to Bulk Send Tokens</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Being able to bulk send tokens for any situation is important. Obviously you’re going to want to make sure that you generate the best results for yourself in any given situation. It is definitely not the easiest thing to do as such, but it can be vital for getting the optimum results.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; If you’re not sure how to do this, you’ll probably struggle to get it right. Don’t worry though, because we’re going to help you figure out exactly what to do for the best. Here’s how you can send tokens in bulk using our system.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>1.	Prepare Your Tokens </h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The first thing that you are going to want to do to make sure that your tokens are ready to be sent off is to prepare them for sending. This first step of the process is vital for getting the rest right.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; This means that you need to know critical details like how many tokens you have to send, what types of tokens you’re sending, who you’re sending them to, and any other important information. This will help a lot when it comes to getting things done right on the first attempt.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>2.	Use the Binance System </h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; When you have prepared the tokens that you want to send to people, the next thing that you will need to do is to use the Binance system to send them to people. Thankfully, it is not difficult to distribute tokens in this fashion - we have intentionally designed the system to be easy to work with and highly adaptable. That’s why we put so much emphasis on people using it for themselves.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Just make sure to follow all of the rules and processes which we’ve put into place in order to activate the system. It isn’t difficult to do and it will give you the tools necessary to get the job done. Once you’ve managed to do this, you’ll be in good hands.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>3.	Enjoy!</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; So, you’ve sent all of the tokens. That’s fantastic - you’re ready to get the best possible experiences from beginning to end. Thankfully, it isn’t difficult to get the ideal results. You are free to enjoy whatever kind of options are available to you, and you don’t have to worry about getting the best results. It’s not at all difficult to do and you can reap all of the benefits. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>Final Thoughts</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Getting access to the best possible way to bulk-send tokens isn’t too difficult. You just have to make sure that you have considered all of the facts when doing so to get the best results. Obviously, this can take you some time to get right and you might even find yourself surprised by how easy it is. We’ve tried to make the process as simple as we can because we understand just how important it can be for you. Don’t hesitate to ask any questions if you’ve got thoughts or theories about what we can do for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}