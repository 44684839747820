import React, { Component } from "react";

export default class Blog9 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '100px', minHeight: '90vh' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">Why Binance Smart Chain (BSC) is Making Waves in the World of Crypto</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Binance is the largest crypto exchange by trading volume. But Binance is not just an exchange but a massive ecosystem hosting many projects through its Binance Chain and Binance Smart Chain (BSC). Binance Chain, the home of BNB currency, is an optimized platform to facilitate ultra-fast trading. But Binance chain only managed to achieve this feat by making some tradeoffs. For example, the platform doesn’t support a flexible programming language. To solve this problem, they introduced Binance Smart Chain in September 2020, which is a complete ecosystem for developers of high-performing DApps.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Binance Smart Chain was built to support cross-chain compatibility with Binance Chain to give users the best of both worlds. The platform is easily programmable and supports smart contracts. BSC is powered by a smart contract which makes it functional and compatible with Ethereum Virtual Machine (EVM). The platform was designed to maintain the power of Binance Chain’s high throughput and while introducing smart contracts into its ecosystem.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The fact that BSC is EVM-compatibility makes it easy for users on the platform to port their projects over Ethereum by conducting simple configurations. If you’re one of the DApp developers on BSC, you may have used BNB to facilitate transactions. But did you know that you can also send BNB in bulk? Yes, BinanceSenger is a project that lets users send BNB and BEP20 tokens from a CSV file to different Binance addresses in a single transaction. You may try it out by registering on the platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}