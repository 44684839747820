import React, { Component } from "react";

export default class Blog2 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">BinanceSender: Bulk Send BNB and BEP20 Tokens from a CSV File </h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Binance coin was created to operate as a native crypto asset known as BNB. The coin offers an alternative way to pay for fees charged to traders using the Binance Exchange. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Created in 2017 as a utility token for discounted trading fees, the uses of BNB have expanded to other many applications, including payment for transaction fees on the Binance Chain, entertainment, online transactions (e.g. booking), and financial services. Based on the concept of smart contract, the decentralised applications (DApps) to the Binance Blockchain and its application were scaled further to reach more users by allowing anyone to create BEP20 tokens.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Binance Sender is a project that lets users send BNB and BEP20 tokens from a CSV file to different Binance addresses in a single transaction.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; The Binance family has grown extremely big with so many tokens and DApps,  each coming with a specific use-case. But the most frequent events within the Binance chain family are the sending/receiving of BNB and BEP20 tokens between users. This is why Binance Sender was created: to help users send BNB and BEP20 tokens in batches as a CSV file. Often referred to as a bulk sender or Token Multisender, the project takes into consideration the increased need to send airdrop BNB, distributed tokens, BEP20 tokens, etc.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; If you want to know how to bulk send tokens, or how to distribute Binance tokens to multiple addresses, you can check out <a href="https://binancesender.com/">BinanceSender</a> and see for yourself the many possibilities this tool provides for its users.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}