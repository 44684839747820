import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Home from './components/home/Home';
import Policy from './components/policy/Policy';
import Blogs from './components/blogs/Blogs';
import Blog1 from './components/blogs/Blog1';
import Blog2 from './components/blogs/Blog2';
import Blog3 from './components/blogs/Blog3';
import Blog4 from './components/blogs/Blog4';
import Blog5 from './components/blogs/Blog5';
import Blog6 from './components/blogs/Blog6';
import Blog7 from './components/blogs/Blog7';
import Blog8 from './components/blogs/Blog8';
import Blog9 from './components/blogs/Blog9';
import Blog10 from './components/blogs/Blog10';
import Blog11 from './components/blogs/Blog11';
import Blog12 from './components/blogs/Blog12';
import Blog13 from './components/blogs/Blog13';
import Blog14 from './components/blogs/Blog14';
import Blog15 from './components/blogs/Blog15';
import history from './history';

export default (prop) => (
    <BrowserRouter history={history}>
        <Switch>
            <Route exact path="/" component={() => <Home values={prop.data} />} />
            <Route exact path="/policy" component={() => <Policy values={prop.data} />} />
            <Route exact path="/blogs" component={() => <Blogs values={prop.data} />} />

            <Route exact path="/Binance_Sender_The_Easiest_way_to_send_BNB_BEP20_tokens_to_multiple_addresses" component={() => <Blog1 values={prop.data} />} />
            <Route exact path="/BinanceSender_Bulk_Send_BNB_and_BEP20_Tokens_from_a_CSV_File" component={() => <Blog2 values={prop.data} />} />
            <Route exact path="/How_To_Distribute_Tokens_For_Your_Binance_Giveaway" component={() => <Blog3 values={prop.data} />} />
            <Route exact path="/How_to_send_multiple_transactions_on_the_Binance_network" component={() => <Blog4 values={prop.data} />} />
            <Route exact path="/How_to_Bulk_Send_Tokens" component={() => <Blog5 values={prop.data} />} />
            <Route exact path="/How_to_Distribute_Binance_Tokens_to_Multiple_Addresses" component={() => <Blog6 values={prop.data} />} />
            <Route exact path="/BNB_Airdrops_and_How_You_Can_Send_Them_in_Bulk" component={() => <Blog7 values={prop.data} />} />
            <Route exact path="/How_to_Access_the_Best_Airdrops_Offers_in_the_Market" component={() => <Blog8 values={prop.data} />} />
            <Route exact path="/Why_Binance_Smart_Chain_BSC_is_Making_Waves_in_the_World_of_Crypto" component={() => <Blog9 values={prop.data} />} />
            <Route exact path="/Why_BNB_Should_be_Part_of_Your_Investment_Portfolio" component={() => <Blog10 values={prop.data} />} />
            <Route exact path="/Why_decentralized_finance_DeFi_is_the_future_of_finance" component={() => <Blog11 values={prop.data} />} />
            <Route exact path="/Our_Hopes_For_the_Future_of_Binance_Tokens" component={() => <Blog12 values={prop.data} />} />
            <Route exact path="/The_Top_Benefits_to_Using_Binance_Sender" component={() => <Blog13 values={prop.data} />} />
            <Route exact path="/What_to_Look_For_in_a_Token_Multisender" component={() => <Blog14 values={prop.data} />} />
            <Route exact path="/Why_Your_Binance_Tokens_Business_Needs_Binance_Sender" component={() => <Blog15 values={prop.data} />} />
        </Switch>

        <footer id="footer">
            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>BinanceSender</span></strong> 2021. All Rights Reserved &nbsp;&nbsp;&nbsp;&nbsp;| <Nav.Link as={Link} to="/policy" style={{ width: '200px', display: 'inline' }}><span>Privacy Policy</span></Nav.Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;|<Nav.Link as={Link} to="/blogs" style={{ width: '200px', display: 'inline' }}><span>Blogs</span></Nav.Link>
                </div>
            </div>
        </footer>
    </BrowserRouter>
);
