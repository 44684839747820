import React, { Component } from "react";

export default class Blog8 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">How to Access the Best Airdrops Offers in the Market</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; One of the most popular ways of getting involved with a new project is getting to participate in the early stages of its development and launch. During this time, the projects give a lot of free tokens in the name of airdrops. However, some airdrops may not be beneficial because they are either given out by scam projects or they just don’t pick up. If you want to own cryptocurrency through airdrops, without spending a dime, then you need to check platforms with good reputations and proper listing criteria. The following are the basic criteria to consider when looking for the best airdrops listings where you can participate and earn some new but promising crypto tokens or coins. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; <b>They are free :</b>  While some platforms charge users to access, the best platforms don’t charge a dime to access their airdrop listings. All you need is to sign up and access all the listed airdrops.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; <b>Real-time listings:</b> the best platforms have a real-time listing as the project happens. These platforms have a big network of all the crypto projects, ICOs, IEOs, and events, hence get the information and list real-time. </p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; <b>Project verification criteria:</b> Check if the platform verifies all its listed airdrops. Knowing the criteria they use can also help you identify if the airdrops are worth considering. All you want is to earn some valuable crypto tokens that can be profitable in the end, so getting involved with projects that have been verified can be helpful in the end.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Did you know you can now send your BNB airdrops in bulk?</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; Yes,<a href="https://binancesender.com/">BinanceSender</a>  is helping users send BNB and BEP20 tokens in batches as a CVS file. All you need is to sign up on the platform and you’re good to go.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}