import React, { Component } from "react";

export default class Blog11 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop() {
        var ele = document.getElementById("blog");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    render() {
        return (
            <div className="container" id="blog" style={{ paddingTop: '170px' }}>
                <div className="calc-wrap">
                    <div className="row aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                            <div className="token-wrap">
                                <div className="token-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2 className="text-center">Why decentralized finance (DeFi) is the future of finance</h2><br />
                                                <div className="line-dec"></div>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; While Bitcoin has been stealing the limelight for its erratic price movements over the last few months, DeFi projects are mushrooming everywhere with massive promise to revolutionize the financial industry. DeFi is promising to make financial services reach the world’s 1.7 billion unbanked, by lowering financial transaction costs. The traditional financial system has traditionally locked out the poor from accessing financial services due to the high cost of transactions, inaccessibility, and slow transaction speed. This is why DeFi projects are seen to be moving in the right direction, providing services that have traditionally remained the preserve of rich countries.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; DeFi, a peer-to-peer decentralized system, is not controlled by any central authority such as banks or government agencies. The decentralization makes DeFi cheap, fast, and efficient for users. For example, DApp projects dealing with loan services have made loans more accessible and cheaper to users. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="section-heading">
                                                <h2>DApps and the User Journey</h2><br />
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; One of the major challenges of DApps is the daunting barrier to entry for first-time users entering the DeFi ecosystem. To use DApp, one must first create their own digital wallet and buy whichever tokens the DApp requires. In this case, users often choose to buy tokens from an external crypto exchange, which adds a layer of friction to the onboarding experience. However, this challenge has been minimized with Binanance Smart Chain, which has made it possible to port projects from Ethereum, powered by BNB.</p>
                                                <p className="blog-content"><i class="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp; To make it even easier with BNB, another application to help users transfer BNB, BEP20 tokens, or any other compatible token has emerged.<a href="https://binancesender.com/">BinanceSender</a> is a multi-sender that lets users send BNB and BEP20 tokens from a CSV file to different Binance addresses in a single transaction.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}