import React, { Component } from "react";
import Web3 from 'web3';
import { BrowserRouter, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { BscConnector } from '@binance-chain/bsc-connector';
import _ from 'lodash';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Routes from './Routes';
import './App.css';
import { ADDRESS_MAINNET, ADDRESS_TESTNET } from './config';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      web3: null,
      isEnabled: false,
      chainId: null,
      account: null,
      ADDRESS_MAIN: null,
      currentPath: window.location.pathname,
      networkType: '',
      bnbUrl: 'https://bscscan.com/address/',
      isConnecting: false,
      isSubWallet: false
    }
    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 60000
    }
  }

  componentDidMount() {
    this.setPathName();
  }

  setPathName() {
    setTimeout(() => {
      if (window.location.pathname != this.state.currentPath) {
        this.setState({
          currentPath: window.location.pathname
        })
      }
      this.setPathName()
    }, 2000)
  }

  async showSubWallet() {
    this.setState({ isSubWallet: true })
  }

  async connectToMetaMaskNetwork() {
    if (this.state.isConnecting) {
      return;
    }
    this.setState({ isConnecting: true });
    if (window.ethereum) {
      await window.ethereum.enable().then((err, res) => {
        // console.log("Res")
      });
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('networkChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.ethereum.on('chainChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.ethereum.on('accountsChanged', chainId => {
        // handle the new network
        document.location.reload();
      })
      window.ethereum.on("disconnect", chainId => {
        document.location.reload();
      });
      try {
        // console.log("YES:::")
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::", e)
        await this.setState({ isEnabled: false });
      }

    } else if (window.web3 && window.web3.currentProvider) {
      // console.log("DONE::")
      window.web3.on('networkChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.web3.on('chainChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.web3.on('accountsChanged', chainId => {
        // handle the new network
        document.location.reload();
      })
      window.web3.on("disconnect", chainId => {
        document.location.reload();
      });
      this.state.web3 = new Web3(window.web3.currentProvider);
      try {
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::")
        await this.setState({ isEnabled: false, isConnecting: false });
      }
    } else {
      await this.setState({ isEnabled: false, isConnecting: false });
    }
  }

  async connectToBinanceWallet() {
    if (window.BinanceChain) {
      if (this.state.isConnecting) {
        return;
      }
      this.setState({ isConnecting: true });
      const bsc = new BscConnector({
        supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
      })
      await bsc.activate();
      if (window.BinanceChain.isConnected()) {
        window.BinanceChain.on('networkChanged', chainId => {
          // handle the new network
          // document.location.reload();
          this.setConfig();
        })
        window.BinanceChain.on('chainChanged', chainId => {
          // handle the new network
          // document.location.reload();
          this.setConfig();
        })
        window.BinanceChain.on('accountsChanged', chainId => {
          // handle the new network
          document.location.reload();
        })
        window.BinanceChain.on("disconnect", (code, reason) => {
          console.log(code, reason);
          document.location.reload();
        });

        this.state.web3 = new Web3(window.BinanceChain);
        try {
          this.setConfig();
        } catch (e) {
          // User has denied account access to DApp...
          // console.log("ERROR:::::::::::::::::::::")
          await this.setState({ isEnabled: false, isConnecting: false });
        }
      }
    }
  }

  async setConfig() {
    console.log("Network changed")
    const Id = await this.state.web3.eth.net.getNetworkType();
    const chainId = await this.state.web3.eth.getChainId();
    const accounts = await this.state.web3.eth.getAccounts();
    this.state.web3.eth.defaultAccount = accounts[0];
    this.configNetwork(Id, chainId, accounts[0])
  }

  async configNetwork(Id, chainId, account) {
    if (chainId !== 56 && chainId !== 97) {
      toastr.info("Wrong network choosen.");
      await this.setState({ isEnabled: false, isConnecting: false });
      return;
    } else if (chainId === 56 || chainId === 97) {
      let contAddrMain = null;
      let bnbUrl = "";
      if (chainId === 97) {
        Id = "BSC testnet";
        contAddrMain = ADDRESS_TESTNET;
        bnbUrl = 'https://testnet.bscscan.com/address/';
      } else if (chainId === 56) {
        Id = "BSC mainnet";
        contAddrMain = ADDRESS_MAINNET;
        bnbUrl = 'https://bscscan.com/address/';
      }

      await this.setState({
        isEnabled: true,
        networkType: Id,
        chainId: chainId,
        account: account,
        bnbUrl: bnbUrl,
        ADDRESS_MAIN: contAddrMain,
      });
    }
  }

  resetPage() {
    setTimeout(() => {
      window.location.reload();
    })
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter >
          <header id="header" className="fixed-top">
            <div className="social">
              <ul>
                {/* <li><a href="https://www.facebook.com/Tron-Sender-101873368450549/" target="_blank"><i className="icofont-facebook"></i></a></li>
                <li><a href="https://twitter.com/BinanceSender" target="_blank"><i className="icofont-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/company/binance-sender/" target="_blank"><i className="icofont-linkedin"></i></a></li> */}
                <li><a href="https://t.me/joinchat/Liv69U33iXpjNjE0" target="_blank"><i className="icofont-telegram"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCiQsAjenqKJZbnfmzDgygNA" target="_blank"><i className="icofont-youtube-play"></i></a></li>
                <li><a href="https://medium.com/@BinanceSender" target="_blank"><img src="assets/img/medium.png" style={{ width: '30px', marginTop: '-4px' }} ></img></a></li>
                <li><a href={this.state.bnbUrl + ADDRESS_MAINNET} target="_blank"><i className="icofont-database"></i></a></li>
              </ul>
            </div>
            <div className="container d-flex align-items-center">
              <Nav.Link as={Link} to="/" onClick={() => this.resetPage()} className="logo ml-auto mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></Nav.Link>
            </div>

            <div className="connect">
              <ul>
                <li>{this.state.isEnabled ? (
                  <div id="networkContainer" style={{ display: 'block' }}>
                    <span className="liveNetwork"></span>
                    <span className="networkId"> {this.state.networkType} </span>
                    <br />
                    <span><a href={this.state.bnbUrl + this.state.account} target="_blank" style={{ fontSize: '13px' }}>{this.state.account}</a></span>
                  </div>
                ) : ''}</li>
                <li>{this.state.isEnabled ? '' : (
                  this.state.isSubWallet ?
                    <button className="btn-gen animated fadeInUp glow-on-hover"><span className="glow-on-hover2"></span>
                      <img onClick={() => this.connectToBinanceWallet()} style={{ width: '35px', marginLeft: '20px', marginRight: '20px', cursor: 'pointer' }} src="assets/img/binance.svg"></img>
                      <img onClick={() => this.connectToMetaMaskNetwork()} style={{ width: '35px', marginLeft: '20px', marginRight: '20px', cursor: 'pointer' }} src="assets/img/metamask.png"></img>
                    </button>
                    :
                    <button className="btn-gen animated fadeInUp glow-on-hover" onClick={() => this.showSubWallet()}><span className="glow-on-hover2"></span>Connect Wallet</button>
                )}</li>
              </ul>
            </div>
          </header>
          {
            this.state.currentPath == "/" ? (<section id="hero" className="d-flex align-items-center" style={{ height: 'auto', paddingTop: '75px', background: 'rgba(26,26,26,1)', backgroundSize: 'cover' }}>
              <div className="container position-relative text-center" data-aos="zoom-in" data-aos-delay="100" style={{maxWidth:'100%'}}>
                <div className="row">
                  <div className="col-md-3">
                    <img src='/assets/img/audit_binance.png'  style={{width:'175px'}}/>
                  </div>
                  <div className="col-md-6" style={{alignSelf:'center'}}>
                    <p style={{ paddingBottom: '0px' }}>
                      Binance Sender is a tool for distributing BNB and BEP20 tokens to multiple wallet addresses from a CSV or TXT file</p>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </section>
            ) : ''
          }
          <Routes data={this.state} />
        </BrowserRouter>
      </div>
    )
  }
}
